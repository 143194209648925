import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, combineLatest, firstValueFrom, map, tap } from 'rxjs';
import { CloudFunctionsService } from '../../services/cloud-functions.service';
import { AGMedia, AGMediaKeys, MediaContentType } from '@ag-common-lib/public-api';
import { confirm } from 'devextreme/ui/dialog';
import { ItemClickEvent } from 'devextreme/ui/tabs';
import { FILE_NAME_VALIDATION_GROUP, MediaUploaderTabs } from './ag-media-uploader-modal.models';
import { validateDxGroups } from 'ag-common-svc/lib/utils/validation';

export interface ImageData {
  contentType: string;
  fileName: string;
  base64: string;
}
@Injectable()
export class AgMediaUploaderModalService {
  private _image$ = new BehaviorSubject<ImageData>(null);
  image$ = this._image$.asObservable();

  private readonly _inProgress$ = new BehaviorSubject<boolean>(false);
  inProgress$ = this._inProgress$.asObservable();

  private _selectedTab$ = new BehaviorSubject<any>({ itemIndex: 0, id: MediaUploaderTabs.FromFile });
  selectedTab$ = this._selectedTab$.asObservable();
  selectedTabIndex$ = this._selectedTab$.pipe(map(tab => tab?.itemIndex));

  selectedFromGallery$ = new BehaviorSubject<AGMedia>(null);

  isImageSelected$ = combineLatest({ image: this._image$, selectedFromGallery: this.selectedFromGallery$ }).pipe(
    map(({ image, selectedFromGallery }) => {
      return !!image?.base64 || !!selectedFromGallery;
    }),
  );

  private _urlPrefix: string;

  constructor(
    private toastrService: ToastrService,
    private cloudFunctions: CloudFunctionsService,
  ) {}

  setImage = (image?: ImageData) => {
    this._image$.next(image);
  };

  setSelectedFromGallery = (mediaItem: AGMedia) => {
    this.selectedFromGallery$.next(mediaItem);
  };

  setPrefix = (urlPrefix: string) => {
    this._urlPrefix = urlPrefix;
  };

  handleSave = async () => {
    const tab = this._selectedTab$.value;
    const tabData = tab?.itemData;

    if (tabData?.id === MediaUploaderTabs.Gallery) {
      return this.selectedFromGallery$.value;
    }

    const isValid = await validateDxGroups(FILE_NAME_VALIDATION_GROUP);

    if (!isValid) {
      throw new Error('formValidation');
    }

    return await this.saveImage();
  };

  private saveImage = async (): Promise<AGMedia> => {
    this._inProgress$.next(true);

    const { base64, contentType, fileName } = this._image$.value;
    const stats = await this.getImageDimensionsFromBase64(base64);

    return this.cloudFunctions
      .uploadMedia({
        mediaData: base64,
        filePath: this._urlPrefix,
        contentType: contentType,
        metadata: {
          fileName,
        },
      })
      .then(path => {
        this.toastrService.success('Image Successfully Uploaded');

        return {
          [AGMediaKeys.wasabiPath]: path,
          [AGMediaKeys.mediaType]: MediaContentType.image,
          [AGMediaKeys.fileName]: fileName,
          [AGMediaKeys.contentType]: contentType,
          [AGMediaKeys.aspectRation]: stats?.aspectRatio ?? null,
        };
      })
      .catch(e => {
        this.toastrService.error('Failed to Upload Image');
        throw new Error(e);
      })
      .finally(() => {
        this._inProgress$.next(false);
      });
  };

  onSelectedIndexChange = async (e: ItemClickEvent) => {
    const itemIndex = e.itemIndex;
    const itemData = e?.itemData;
    const isImageSelected = await firstValueFrom(this.isImageSelected$);

    if (!isImageSelected) {
      this._selectedTab$.next({ itemIndex, itemData });
      return;
    }

    const continueWithoutSaving = await confirm(
      '<i>You are have not saved data. <br/> Are you sure you want to Switch without Saving?</i>',
      'Confirm',
    );

    if (continueWithoutSaving) {
      this.setSelectedFromGallery(null);
      this.setImage(null);
      this._selectedTab$.next({ itemIndex, itemData });
      return;
    }

    const currentTab = this._selectedTab$.value;
    this._selectedTab$.next(null);
    setTimeout(() => {
      this._selectedTab$.next(currentTab);
    }, 0);
  };

  setSelectedTab = tab => {
    this._selectedTab$.next(tab);
  };

  onCancelEdit = async (onAfterRevertChanges?: () => void): Promise<boolean> => {
    this.selectedFromGallery$.next(null);
    this._image$.next(null);

    onAfterRevertChanges && onAfterRevertChanges();
    return true;
  };

  private getImageDimensionsFromBase64(
    base64: string,
  ): Promise<{ width: number; height: number; aspectRatio: number }> {
    return new Promise((resolve, reject) => {
      const img = new Image();

      img.src = `data:image/png;base64,${base64}`;

      img.onload = () => {
        const width = img.width;
        const height = img.height;
        const aspectRatio = Number((width / height).toFixed(2));
        resolve({ width, height, aspectRatio });
      };

      img.onerror = err => {
        resolve(null);
      };
    });
  }
}
