import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { UntilDestroy } from '@ngneat/until-destroy';
import { AGMedia } from '@ag-common-lib/public-api';
import { FILE_NAME_VALIDATION_GROUP, MediaUploaderTabs } from '../ag-media-uploader-modal.models';
import { AgMediaUploaderModalService, ImageData } from '../ag-media-uploader-modal.service';
import { UploadFromDiskTabComponent } from '../upload-from-disk-tab/upload-from-disk-tab.component';
import { UploadFromUrlTabComponent } from '../upload-from-url-tab/upload-from-url-tab.component';

@UntilDestroy()
@Component({
  selector: 'ag-shr-media-sources',
  templateUrl: './media-sources.component.html',
  styleUrls: ['./media-sources.component.scss'],
})
export class MediaSourcesComponent {
  @Input() accept = ['image/*'];
  @Input() set mediaPathPrefix(mediaPathPrefix: string) {
    this.mediaUploaderService.setPrefix(mediaPathPrefix);
  }
  @Input() set mediaSources(data: MediaUploaderTabs[]) {
    this.getMediaSources(data);
  }
  @Input() mediaItems: AGMedia[];

  @ViewChild(UploadFromDiskTabComponent, { static: false }) uploadFromDiskTabComponent: UploadFromDiskTabComponent;
  @ViewChild(UploadFromUrlTabComponent, { static: false }) uploadFromUrlTabComponent: UploadFromUrlTabComponent;

  protected tabConfig: any = this.getMediaSources([
    MediaUploaderTabs.FromFile,
    MediaUploaderTabs.ByURL,
    MediaUploaderTabs.Gallery,
  ]);

  protected MediaUploaderTabs = MediaUploaderTabs;
  protected FILE_NAME_VALIDATION_GROUP = FILE_NAME_VALIDATION_GROUP;
  protected selectedTabIndex$ = this.mediaUploaderService.selectedTabIndex$;
  protected selectedTab$ = this.mediaUploaderService.selectedTab$;
  protected inProgress$: Observable<boolean>;
  protected isImageSelected$: Observable<boolean>;
  protected imageData$: Observable<ImageData>;

  constructor(private mediaUploaderService: AgMediaUploaderModalService) {
    this.inProgress$ = this.mediaUploaderService.inProgress$;
    this.isImageSelected$ = this.mediaUploaderService.isImageSelected$;
    this.imageData$ = this.mediaUploaderService.image$;
  }

  protected onSelectedIndexChange = this.mediaUploaderService.onSelectedIndexChange;

  private getMediaSources(data: MediaUploaderTabs[]) {
    const tabConfig = data.map(mediaSource => ({ id: mediaSource, text: mediaSource }));
    this.tabConfig = tabConfig;
    this.mediaUploaderService.setSelectedTab({ itemIndex: 0, itemData: tabConfig[0] });
  }
}
