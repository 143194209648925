import { Component, EventEmitter, HostBinding, Input, Output, ViewChild } from '@angular/core';
import { DxFileUploaderComponent } from 'devextreme-angular';
import { AgMediaUploadModel } from '../ag-media-uploader-modal.models';
import { BehaviorSubject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { extensionToMimeMap } from '../ag-media-uploader-modal.utils';
import { getFileExtension } from '../../../utils/file.utils';

@Component({
  selector: 'ag-shr-upload-from-disk-tab',
  templateUrl: './upload-from-disk-tab.component.html',
  styleUrls: ['./upload-from-disk-tab.component.scss'],
})
export class UploadFromDiskTabComponent {
  @HostBinding('class') className = 'upload-from-disk-tab';

  @ViewChild('fileUploader') fileUploader!: DxFileUploaderComponent;

  @Input() accept = ['image/*'];
  @Input() placeholder = 'Click or Drag a Image';
  @Input() size: 'small' | 'medium' | 'large' | 'unspecified' = 'unspecified';
  @Output() mediaUrlChange = new EventEmitter<AgMediaUploadModel | null>();
  @Output() onFileUpload = new EventEmitter<{
    contentType: string;
    fileName: string;
    base64: string;
    file: File;
  } | null>();
  @Output() onClearFile = new EventEmitter();

  imagePreviewUrl: string;
  fileName: string;

  private _file: File;
  private _isFileValid$ = new BehaviorSubject(true);

  constructor(private toastrService: ToastrService) {}

  handleIsFileValid = (isValid): void => {
    this._isFileValid$.next(isValid);

    if (isValid) {
      this.fileName = this._file.name;
      this.onFileUpload.emit({
        contentType: this._file.type,
        fileName: this._file.name,
        base64: this.imagePreviewUrl.split(',')[1],
        file: this._file,
      });
    }
  };

  clearFile() {
    this.imagePreviewUrl = null;
    this.fileName = null;
    this._file = null;
    this.fileUploader.instance.reset(); // Clears the DxFileUploader component
    this.onClearFile.emit(null); // Optionally, emit null to reset the file upload event
  }

  // Method triggered on file input change
  async fileChangeEvent(event: Event) {
    this.imagePreviewUrl = null;
    const file: File = (event?.target as HTMLInputElement)?.files[0];

    if (!file) {
      return;
    }

    this._file = file;

    // Convert file to Base64 for other types
    const fileBase64 = await this.fileToBase64(file).catch(() => {
      console.error('Error converting file to base64');
      return null;
    });

    const isEmailFile = this.isEmailFile(file);
    console.log('isEmailFile', isEmailFile);

    if (isEmailFile) {
      this.imagePreviewUrl = '/assets/file-placeholders/email-opened.png';
      this.fileName = file.name;

      this.onFileUpload.emit({
        contentType: file.type,
        fileName: file.name,
        base64: fileBase64.split(',')[1],
        file: file,
      });
      return;
    }

    // Validate the file
    const isFileValid = this.validateFile(file);
    if (!isFileValid) {
      return;
    }

    if (fileBase64) {
      this.imagePreviewUrl = fileBase64;

      // Emit file metadata along with base64 for non-email files
      this.onFileUpload.emit({
        contentType: file.type,
        fileName: file.name,
        base64: fileBase64.split(',')[1],
        file: file, // Emit the original file
      });
    }
  }

  // Method to convert file to Base64
  fileToBase64(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = reject;
    });
  }

  clear() {
    this.imagePreviewUrl = null;
    this.fileName = null;
    this._file = null;
  }

  private isEmailFile(file: File): boolean {
    const isEmailType = file.type === 'message/rfc822' || file.type === 'application/vnd.ms-outlook';
    const isEmailExtension = file.name.endsWith('.eml') || file.name.endsWith('.msg');

    return isEmailType || isEmailExtension;
  }

  private validateFile(file: File): boolean {
    return this.validateSize(file.size) && this.isFileTypeAllowed(file);
  }

  private isFileTypeAllowed(file: File): boolean {
    const { type, name } = file;

    const isMimeTypeValid = this.accept.some(acceptedType => type.match(acceptedType));

    const extension = getFileExtension(name);
    const isExtensionValid = this.accept.some(acceptedType => this.extensionMatchesMimeType(acceptedType, extension));

    // Additional validation for .msg (Outlook) files
    if (extension === 'msg') {
      return this.validateOutlookFile(type);
    }

    if (!isMimeTypeValid && !isExtensionValid) {
      this.toastrService.error('Invalid file type! Only specific types are allowed.');
      return false;
    }

    return true;
  }

  private extensionMatchesMimeType(acceptedType: string, extension: string): boolean {
    return extensionToMimeMap[extension] === acceptedType;
  }

  // Further validation for specific file types
  private validateOutlookFile(type: string): boolean {
    return type === 'application/vnd.ms-outlook' || type === '';
  }

  private validateSize(fileSize: number): boolean {
    const allowedSizeMb = 10;
    const maxAllowedSize = allowedSizeMb * 1024 * 1024; // MB => bytes
    if (fileSize > maxAllowedSize) {
      this.toastrService.error(
        `File is too big! Max allowed size is ${allowedSizeMb}MB. Image 1920 px width or height is recommended.`,
      );
      return false;
    }
    return true;
  }
}
