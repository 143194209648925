export const fileToUIBase64 = (file): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = reject;
  });

export const buildUIBase64 = (base64: string, contentType: string = 'application/octet-stream') => {
  return `data:${contentType};base64,${base64}`;
};

export const decodeBase64 = (base64String: string): string => {
  const base64WithoutPrefix = base64String.split(',')[1];
  return atob(base64WithoutPrefix);
}

export const extensionToMimeMap: { [key: string]: string } = {
  'eml': 'message/rfc822',
  'msg': 'application/vnd.ms-outlook',
  'mhtml': 'multipart/related',
  // ...
};
