export enum FileUploaderTabs {
  fromFile = 'fromFile',
  byURL = 'byURL',
}

export const FileUploaderTabText = {
  [FileUploaderTabs.fromFile]: 'From File',
  [FileUploaderTabs.byURL]: 'By URL',
};

export interface FileData {
  contentType: string;
  fileName: string;
  base64: string;
  fileSize: number;
  file?: File;
}
