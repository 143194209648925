import { Component, EventEmitter, HostBinding, Input, OnInit, Output, ViewChild } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { FileUploaderService } from './file-uploader.service';
import { FileData, FileUploaderTabs, FileUploaderTabText } from './file-uploader.models';
import { DxButtonTypes } from "devextreme-angular/ui/button";

@Component({
  selector: 'ag-file-uploader',
  templateUrl: './file-uploader.component.html',
  styleUrl: './file-uploader.component.scss',
  providers: [FileUploaderService],
})
export class AGFileUploaderComponent implements OnInit {
  @Input() accept: string[] = ['*/*'];
  @Input() validEmailExtensions = ['.eml', '.msg'];
  @Input() placeholder: string = 'Click or Drag Existing File';
  @Input() allowClearing: boolean = false;
  @Input() tabs: FileUploaderTabs[] = [FileUploaderTabs.fromFile, FileUploaderTabs.byURL];

  @Output() fileParsed = new EventEmitter<FileData>();
  @Output() onClearFile = new EventEmitter<void>();

  @HostBinding('class') className = 'file-uploader';

  @ViewChild('fileNameValidatorRef', { static: false }) fileNameValidatorComponent;

  selectedTabIndex$ = new BehaviorSubject<number>(0);
  fileData$: Observable<FileData>;
  tabConfig: Array<{ text: string }> = [];

  clearButton: DxButtonTypes.Properties = {
    icon: 'remove',
    stylingMode: 'text',
    disabled: false,
    onClick: () => {
      this.clearFile();
    },
  };

  ngOnInit() {
    this.tabConfig = this.tabs.map(tab => ({
      text: FileUploaderTabText[tab],
    }));
  }

  constructor(private fileUploaderService: FileUploaderService) {
    this.fileData$ = this.fileUploaderService.file$;
  }

  onSelectedIndexChange(e: any): void {
    this.selectedTabIndex$.next(e.itemIndex);
  }

  onFileUpload(fileData: { contentType: string; fileName: string; base64: string; file?: File }) {
    this.fileUploaderService.createFileData(fileData.file).then(fileParsed => this.fileParsed.emit(fileParsed));
  }

  clearFile() {
    this.fileUploaderService.clearFile();
    this.onClearFile.emit();
  }
}
