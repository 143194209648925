import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { FileData } from './file-uploader.models';
import { fileToUIBase64 } from '../ag-media-uploader-modal.utils';

@Injectable({
  providedIn: 'root',
})
export class FileUploaderService {
  private _file$ = new BehaviorSubject<FileData>(null);
  file$ = this._file$.asObservable();

  private _inProgress$ = new BehaviorSubject<boolean>(false);
  inProgress$ = this._inProgress$.asObservable();

  private _isFileSelected$ = new BehaviorSubject<boolean>(false);
  isFileSelected$ = this._isFileSelected$.asObservable();

  constructor(private toastrService: ToastrService) {}

  async createFileData(file: File): Promise<FileData> {
    const base64Data = await fileToUIBase64(file);
    const fileData: FileData = {
      contentType: file.type,
      fileName: file.name,
      base64: base64Data,
      fileSize: file.size,
      file: file,
    };

    this._file$.next(fileData);
    this._isFileSelected$.next(true);

    return fileData;
  }

  // Reset the file data
  clearFile(): void {
    this._file$.next(null);
    this._isFileSelected$.next(false);
  }
}
